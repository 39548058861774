* {
  margin: 0;
  padding: 0;
}
html,
body {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Staatliches', cursive;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.right-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.main-title {
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 1em;
  font-family: 'Roboto Mono', monospace;
}
.ctn-home {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  top: 10%;
  left: 50%;
  height: 80%;
  width: 100%;
  transform: translate(-50%);
  opacity: 1;
  transition: 1s ease all;
}
.logo_sml {
  position: absolute;
  top: 20px;
  left: 20px;
  transition: 0.5s ease-in-out all;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
}
.ctn-home h1 {
  font-size: 5em;
}
.burger-menu-btn {
  position: absolute;
  top: 40px;
  right: 50px;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-flow: column nowrap;
  height: 20px;
  cursor: pointer;
}
.burger-menu-btn:hover .bar-1 {
  transform: rotate(45deg) translateY(12px);
}
.burger-menu-btn:hover .bar-2 {
  transform: translateX(30px);
  opacity: 0;
}
.burger-menu-btn:hover .bar-3 {
  transform: rotate(-45deg) translateY(-12px);
}
.burger-menu-btn .bar {
  height: 2px;
  width: 30px;
  background: #000;
  border-radius: 1px;
  transition: 0.3s ease all;
}
.btn-home {
  width: 50px;
  height: 50px;
  border: 2px #000 solid;
  border-radius: 100%;
  position: relative;
  cursor: pointer;
}
.content-container {
  perspective: 800px;
  position: absolute;
  top: 40%;
  left: 15%;
  transform: translateY(-50%);
  height: 60%;
  width: 40%;
  z-index: 2;
}
video {
  width: 100%;
  height: 500px;
  cursor: pointer;
  object-fit: contain;
}
.medium-vid {
  height: 60%;
  background: #000;
}
.content-manager {
  position: absolute;
  display: none;
  flex-flow: column nowrap;
  opacity: 0;
  transition: 0.5s ease all;
  font-size: 2em;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: #000;
  transform: rotate3d(0, 1, 0, 3deg);
  will-change: transform;
}
.content-right-main {
  animation: float-right 10s ease-in-out 0s infinite;
}
.content-para {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.6em;
  margin-top: 5px;
  margin-bottom: 5px;
}
.bar-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  width: 35px;
  height: 2px;
  background: #000;
  transition: 1s ease all;
}
.btn-ctn {
  width: 400px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: 1s ease all;
}
.numerotation {
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.link_big {
  text-decoration: none;
  color: #000;
}
.link-container {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 40%);
  width: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  z-index: 2000;
  overflow: hidden;
  opacity: 0.5;
  transition: 0.5s ease all;
}
.link-container a:hover {
  opacity: 1;
}
.link {
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  color: #000;
  font-family: 'Roboto Mono', monospace;
  opacity: 0.6;
  margin: 0 2%;
  transition: 0.5s ease all;
}
.link:hover {
  opacity: 1;
}
.link:visited {
  color: #000;
}
.cursor_hold,
.num_bar {
  width: 30px;
  height: 2px;
  background: #000;
  transition: 0.5s ease transform;
  transform: scaleX(0);
}
.overflow {
  width: 60px;
  overflow: hidden;
}
.nb-num {
  color: #9a9a9a;
  transition: 1s ease all;
  transform-origin: left;
  cursor: pointer;
  font-size: 1.7em;
  font-family: 'Roboto Mono', monospace;
}
.video-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}
.circle-ctn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.video-loader .circle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #90119b;
  margin: 20px;
  will-change: all;
}
.dumb-btn {
  font-family: 'Staatliches', cursive !important;
  transform: rotate(90deg);
  font-size: 2em;
  cursor: pointer;
  margin-top: 3px;
  display: flex;
}
.dumb-btn span {
  margin-left: 1px;
  transition: 0.3s ease all;
}
.dumb-btn:hover span:nth-child(odd) {
  transform: translateY(3px);
}
.dumb-btn:hover span:nth-child(even) {
  transform: translateY(-3px);
}
.circle-1 {
  animation: loading-forward 3s ease-in-out 0s infinite reverse;
}
.circle-2 {
  animation: loading-back 3s ease-in-out 0s infinite reverse;
}
.video-ctn-content {
  position: relative;
}
.bottom-btn-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  height: 0;
}
.sml-btn {
  cursor: pointer;
}
.home-ctn-btn {
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
}
.date-content {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.7em;
}
.bar-btn-home {
  width: 100%;
  height: 2px;
  background: #000;
  margin-top: -5px;
}
.btn-home:hover .bar-btn {
  animation: translate 1s ease-in-out 0s infinite normal forwards;
  text-decoration: none;
}
.overflow-txt {
  overflow: hidden;
  position: relative;
  height: 40px;
  width: 400px;
}
.txt-slider-ctn {
  position: absolute;
  top: 10%;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  transition: 1s ease all;
}
.text-inner {
  width: 400px;
  font-size: 2.5em;
  margin-top: -10px;
  font-family: 'Staatliches', cursive;
  text-align: center;
}
.inner-dumb {
  display: block;
}
.cursor-hold {
  position: absolute;
  top: 100%;
  left: 0;
  color: #000;
  transition: 0.5s ease opacity;
}
.sml-bar {
  width: 100%;
  height: 2px;
  background: #000;
  transform: scaleX(0);
  transition: 0.1s linear all;
  transform-origin: left;
}
.modal-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s ease all;
}
.link-container {
  z-index: 2;
}
.big-nb {
  position: absolute;
  top: -30%;
  left: -25%;
  font-size: 14em;
  opacity: 0.6;
  color: #000;
  z-index: -1;
  transform: rotate3d(0, 1, 0, 3deg);
  animation: float-up 15s linear 0s infinite normal forwards;
  will-change: transform;
}
.modal-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  opacity: 0.4;
  z-index: 1001;
}
.modal-video {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.video-ctn {
  position: relative;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.cross-ctn {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: url(images/cross.png);
  background-size: contain;
  opacity: 0.8;
  transition: 1s ease all;
}
.cross-ctn:hover {
  opacity: 1;
}
.credit {
  position: absolute;
  bottom: 10px;
  font-size: 0.6em;
  color: #000;
  font-family: 'Roboto Mono', monospace;
  opacity: 0.9;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  z-index: 10000;
  transition: 0.4s ease-out all;
}
.link_sml {
  text-decoration: underline;
  font-size: 1em;
  color: #000;
}
.responsive-msg {
  position: absolute;
  display: none;
  bottom: 15%;
  left: 50%;
  font-size: 0.6em;
  color: #000;
  font-family: 'Roboto Mono', monospace;
  opacity: 0;
  transform: translate(-50%, 0%);
  width: 100%;
  z-index: 0;
  text-align: center;
  will-change: opacity;
}
.scroll_container {
  position: absolute;
  display: none;
  bottom: 75px;
  left: 50%;
  transform: translate(-50%, 0%);
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
  opacity: 0.7;
}
.scroll_container span {
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-msg {
  height: 30px;
  width: 2px;
  background: #000;
  margin: auto;
  animation: scroll 1s ease-in-out 0s infinite normal forwards;
}
.responsive-msg-cursor {
  position: absolute;
  display: none;
  bottom: -13%;
  left: 61%;
  font-size: 0.6em;
  color: #000;
  opacity: 1;
  width: auto;
  text-align: left;
  will-change: opacity;
}
.img-holder {
  width: 100%;
  height: 500px;
}
.swiper-slide {
  display: flex;
  justify-content: center;
}
.img-content-double {
  height: 100%;
  width: 35%;
  object-fit: contain;
}
.img-content {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 50px;
  z-index: 1000;
  cursor: pointer;
  transition: 1s ease all;
  display: none;
}
.slider-arrow img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.slider-arrow-left {
  left: 5%;
}
.slider-arrow-right {
  right: 5%;
  transform-origin: center;
  transform: rotate(180deg) translateY(50%);
}
.swiper-slide {
  height: 100%;
}
.swiper-wrapper {
  height: 100%;
}
.swiper-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #f00;
  opacity: 0;
}
.intro__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-flow: column nowrap;
  font-size: 2em;
  text-align: center;
  transition: 0.5s ease-in-out all;
}
.launch_btn {
  cursor: pointer;
  position: relative;
  font-size: 1.2em;
  width: auto;
  display: inline-block;
  margin: auto;
  margin-top: 100px;
  width: max-content;
}
.colors_change {
  transition: 0.1s ease all;
}
.launch_btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
}
#loading {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  opacity: 1;
  transition: 0.5s ease-in-out all;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loading img {
  width: 50%;
  height: 50%;
  object-fit: contain;
}
.responsive-btn {
  position: absolute;
  bottom: 125px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  z-index: 0;
  transition: 1s ease all;
  font-size: 1em;
  color: #000;
  font-family: 'Roboto Mono', monospace;
}
/* RESPONSIVE */
@media only screen and (min-height: 1000px) {
  .content-container {
    top: 50%;
  }
}
@media only screen and (max-width: 1400px) {
  .big-nb {
    font-size: 11em;
    top: -25%;
  }
}
@media only screen and (max-width: 1000px) {
  .content-container {
    width: 55%;
  }
  .modal-video {
    width: 90%;
    height: auto;
  }
  .cross-ctn {
    right: 5%;
  }
  .text-inner {
    font-size: 2em;
  }
  .ctn-home h1 {
    font-size: 3em;
  }
}
@media only screen and (max-width: 700px) {
  .content-container {
    width: 80%;
    left: 10%;
    transform: translate(-50%, -50%);
  }
  .big-nb {
    left: -2%;
  }
  .link-container {
    bottom: 40px;
  }
  .responsive-msg {
    animation: blink 2s linear 0s infinite normal forwards;
  }
  .responsive-msg-cursor {
    display: block;
  }
  .scroll_container {
    display: none !important;
  }
}
@media only screen and (max-height: 900px) and (min-width: 800px) {
  .modal-video {
    width: 90%;
    height: auto;
  }
  .content-container {
    top: 40%;
  }
  .link-container {
    bottom: 40px;
  }
  video {
    height: 290px;
  }
}
@media only screen and (max-height: 700px) and (min-width: 500px) {
  .content-container {
    width: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  video {
    width: 50%;
  }
  .content-para {
    font-size: 0.4em;
  }
  .big-nb {
    font-size: 8em;
    left: -5%;
  }
  .date-content {
    font-size: 0.5em;
  }
  .modal-video {
    width: 90%;
    height: auto;
  }
  .credit {
    bottom: 2px;
  }
  .img-holder {
    height: 200px;
  }
}
@media only screen and (max-width: 480px) {
  h2 {
    font-size: 1em;
  }
  .content-para {
    font-size: 0.5em;
  }
  .link-container {
    bottom: 40px;
    width: 100%;
  }
  .link {
    font-size: 0.7em;
  }
  .modal-video {
    width: 90%;
    height: auto;
  }
  .content-container {
    transform: translate(-50%, -55%);
  }
  .big-nb {
    font-size: 8em;
  }
  .dumb-btn {
    font-size: 1em;
  }
  video {
    height: 200px;
  }
  .img-holder {
    height: 200px;
  }
  .content-container {
    top: 45%;
  }
  .responsive-btn {
    display: block;
  }
}
@media only screen and (max-width: 400px) {
  h2 {
    font-size: 0.7em;
  }
  .content-para {
    font-size: 0.4em;
  }
  .date-content {
    font-size: 0.4em;
  }
  .link-container {
    bottom: 30px;
    width: 100%;
  }
  .btn-ctn {
    bottom: 60px;
  }
  .modal-video {
    width: 90%;
    height: auto;
  }
  .content-container {
    transform: translate(-50%, -55%);
    top: 45%;
  }
  .big-nb {
    font-size: 8em;
  }
  .return-home {
    font-size: 0.7em;
  }
  .link_big {
    font-size: 0.7em;
  }
  .credit {
    bottom: 5px;
  }
  .img-holder {
    height: 200px;
  }
}
@-moz-keyframes translate {
  0% {
    transform: translate(0%, -50%);
  }
  25% {
    transform: translate(100%, -50%);
  }
  26% {
    transform: translate(-200%, -50%) scaleX(0);
  }
  100% {
    transform: translate(0%, -50%) scaleX(1);
  }
}
@-webkit-keyframes translate {
  0% {
    transform: translate(0%, -50%);
  }
  25% {
    transform: translate(100%, -50%);
  }
  26% {
    transform: translate(-200%, -50%) scaleX(0);
  }
  100% {
    transform: translate(0%, -50%) scaleX(1);
  }
}
@-o-keyframes translate {
  0% {
    transform: translate(0%, -50%);
  }
  25% {
    transform: translate(100%, -50%);
  }
  26% {
    transform: translate(-200%, -50%) scaleX(0);
  }
  100% {
    transform: translate(0%, -50%) scaleX(1);
  }
}
@keyframes translate {
  0% {
    transform: translate(0%, -50%);
  }
  25% {
    transform: translate(100%, -50%);
  }
  26% {
    transform: translate(-200%, -50%) scaleX(0);
  }
  100% {
    transform: translate(0%, -50%) scaleX(1);
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes scroll {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  49% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  51% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}
@-webkit-keyframes scroll {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  49% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  51% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}
@-o-keyframes scroll {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  49% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  51% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}
@keyframes scroll {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
  49% {
    transform: scaleY(1);
    transform-origin: bottom;
  }
  51% {
    transform: scaleY(1);
    transform-origin: top;
  }
  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}
@-moz-keyframes loading-forward {
  0% {
    transform: translateY(13px);
  }
  25% {
    transform: translateY(-13px);
  }
  50% {
    transform: translateY(13px);
  }
  75% {
    transform: translateY(-13px);
  }
  100% {
    transform: translateY(13px);
  }
}
@-webkit-keyframes loading-forward {
  0% {
    transform: translateY(13px);
  }
  25% {
    transform: translateY(-13px);
  }
  50% {
    transform: translateY(13px);
  }
  75% {
    transform: translateY(-13px);
  }
  100% {
    transform: translateY(13px);
  }
}
@-o-keyframes loading-forward {
  0% {
    transform: translateY(13px);
  }
  25% {
    transform: translateY(-13px);
  }
  50% {
    transform: translateY(13px);
  }
  75% {
    transform: translateY(-13px);
  }
  100% {
    transform: translateY(13px);
  }
}
@keyframes loading-forward {
  0% {
    transform: translateY(13px);
  }
  25% {
    transform: translateY(-13px);
  }
  50% {
    transform: translateY(13px);
  }
  75% {
    transform: translateY(-13px);
  }
  100% {
    transform: translateY(13px);
  }
}
@-moz-keyframes loading-back {
  0% {
    transform: translateY(-13px);
  }
  25% {
    transform: translateY(13px);
  }
  50% {
    transform: translateY(-13px);
  }
  75% {
    transform: translateY(13px);
  }
  100% {
    transform: translateY(-13px);
  }
}
@-webkit-keyframes loading-back {
  0% {
    transform: translateY(-13px);
  }
  25% {
    transform: translateY(13px);
  }
  50% {
    transform: translateY(-13px);
  }
  75% {
    transform: translateY(13px);
  }
  100% {
    transform: translateY(-13px);
  }
}
@-o-keyframes loading-back {
  0% {
    transform: translateY(-13px);
  }
  25% {
    transform: translateY(13px);
  }
  50% {
    transform: translateY(-13px);
  }
  75% {
    transform: translateY(13px);
  }
  100% {
    transform: translateY(-13px);
  }
}
@keyframes loading-back {
  0% {
    transform: translateY(-13px);
  }
  25% {
    transform: translateY(13px);
  }
  50% {
    transform: translateY(-13px);
  }
  75% {
    transform: translateY(13px);
  }
  100% {
    transform: translateY(-13px);
  }
}
@-moz-keyframes float-left {
  0% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
  33% {
    transform: rotate3d(0, 1, 0, 2deg);
  }
  66% {
    transform: rotate3d(0, 1, 0, 4deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
}
@-webkit-keyframes float-left {
  0% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
  33% {
    transform: rotate3d(0, 1, 0, 2deg);
  }
  66% {
    transform: rotate3d(0, 1, 0, 4deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
}
@-o-keyframes float-left {
  0% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
  33% {
    transform: rotate3d(0, 1, 0, 2deg);
  }
  66% {
    transform: rotate3d(0, 1, 0, 4deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
}
@keyframes float-left {
  0% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
  33% {
    transform: rotate3d(0, 1, 0, 2deg);
  }
  66% {
    transform: rotate3d(0, 1, 0, 4deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg);
  }
}
@-moz-keyframes float-right {
  0% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
  33% {
    transform: rotate3d(0, 1, 0, 1deg) translateY(-50%);
  }
  66% {
    transform: rotate3d(0, 1, 0, 5deg) translateY(-50%);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
}
@-webkit-keyframes float-right {
  0% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
  33% {
    transform: rotate3d(0, 1, 0, 1deg) translateY(-50%);
  }
  66% {
    transform: rotate3d(0, 1, 0, 5deg) translateY(-50%);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
}
@-o-keyframes float-right {
  0% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
  33% {
    transform: rotate3d(0, 1, 0, 1deg) translateY(-50%);
  }
  66% {
    transform: rotate3d(0, 1, 0, 5deg) translateY(-50%);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
}
@keyframes float-right {
  0% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
  33% {
    transform: rotate3d(0, 1, 0, 1deg) translateY(-50%);
  }
  66% {
    transform: rotate3d(0, 1, 0, 5deg) translateY(-50%);
  }
  100% {
    transform: rotate3d(0, 1, 0, 3deg) translateY(-50%);
  }
}
@-moz-keyframes float-up {
  0% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
  33% {
    transform: translateY(-1%) rotate3d(0, 1, 0, 5deg);
  }
  66% {
    transform: translateY(1%) rotate3d(0, 1, 0, 3deg);
  }
  100% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
}
@-webkit-keyframes float-up {
  0% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
  33% {
    transform: translateY(-1%) rotate3d(0, 1, 0, 5deg);
  }
  66% {
    transform: translateY(1%) rotate3d(0, 1, 0, 3deg);
  }
  100% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
}
@-o-keyframes float-up {
  0% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
  33% {
    transform: translateY(-1%) rotate3d(0, 1, 0, 5deg);
  }
  66% {
    transform: translateY(1%) rotate3d(0, 1, 0, 3deg);
  }
  100% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
}
@keyframes float-up {
  0% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
  33% {
    transform: translateY(-1%) rotate3d(0, 1, 0, 5deg);
  }
  66% {
    transform: translateY(1%) rotate3d(0, 1, 0, 3deg);
  }
  100% {
    transform: translateY(0%) rotate3d(0, 1, 0, 4deg);
  }
}
